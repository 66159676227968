import { useState, useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import Styles from "../css_modules/Menu.module.css"

const themeColors = {
    light: "#ffffff",
    dark: "#121212",
    blue: "#1e88e5",
    green: "#43a047",
    purple: "#8e44ad",
  };

const ThemeSwitcher = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const themes = ["original", "original_dark", "professional", "professional_dark"];
  const [isOpen, setIsOpen] = useState(false);


  return (
    <div>
      {/* Bouton flottant en bas à droite */}
      <button 
        className={Styles.floatingButton} 
        onClick={() => setIsOpen(!isOpen)}
      >
        🎨
      </button>

      {/* Menu des thèmes (affiché si isOpen est true) */}
      <div className={`${Styles.themeMenu} ${isOpen ? Styles.show : ""}`}>
        {Object.keys(themes).map((t) => (
          <span
            key={t}
            className={Styles.themeOption}
            onClick={() => { setTheme(themes[t]); setIsOpen(false); }}
          >
            {themes[t]}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ThemeSwitcher;