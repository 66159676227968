import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Styles from "../css_modules/Home.module.css"
import { PageContext } from '../contexts/PageContext';
import { SiteSideContext } from '../contexts/SiteSideContext';
import { UserContext } from '../contexts/UserContext';
import Slideshow from "./Slideshow";
import { validUserToken } from '../Utils.js'
import homeItImage from "../images/home_IT.png"
import homeBlogImage from "../images/home_Blog.png"
import homeCausesImage from "../images/Home_causes.png"

function Home({pagesLinks}) {
    console.log(process.env.REACT_APP_ENV);
    return (
        <div className={Styles.homeBody}>
            <h1>Bienvenue sur le site web Darktouffe</h1>
            <Slideshow />
            <HomeListItems pagesLinks={pagesLinks}/>
        </div>
    )
}

function HomeListItems({pagesLinks}) {
    const {setCurrentPage} = useContext(PageContext);
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    const {currentUser} = useContext(UserContext);

    function changeCurrentPageSide(page, sideSite) {
        setCurrentPage(page);
        setCurrentSiteSide(sideSite);
    }

    return (
        <>
            <ul className={Styles.bodyMenu}>
                {pagesLinks.map((pageLink) => {
                    if (pageLink.key === "administration" && validUserToken(currentUser)) {
                        return (
                            
                                <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "admin")}>
                                    {pageLink.icon}
                                    <Link to="/admin"><span>{pageLink.text}</span></Link>
                                </li>
                        )
                    } else if (pageLink.key === "project") {
                        return (
                            
                                <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                    {pageLink.icon}
                                    <Link to="/project"><span>{pageLink.text}</span></Link>
                                </li>
                        )
                    } else if (pageLink.key === "blog") {
                        return (
                            
                                <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                    {pageLink.icon}
                                    <Link to="/blog"><span>{pageLink.text}</span></Link>
                                </li>
                        )
                    }else if (pageLink.key === "contact") {
                        return (
                            
                                <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                    {pageLink.icon}
                                    <Link to="/contact"><span>{pageLink.text}</span></Link>
                                </li>
                        )
                    } else if (pageLink.key === "about") {
                        return (
                            
                                <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                    {pageLink.icon}
                                    <Link to="/about"><span>{pageLink.text}</span></Link>
                                </li>
                        )
                    } else if (pageLink.key !== "home" && pageLink.key !== "administration" && pageLink.key !== "project" && pageLink.key !== "blog") {
                        return (
                            <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                {pageLink.icon}
                                <Link to="/admin"><span>{pageLink.text}</span></Link>
                            </li>
                        )
                    }
                })}
            </ul>
            <div className={Styles.homeProContent}>
                <section>
                    <span>Qu'est-ce que Darktouffe ?</span>
                    <div className={Styles.homeSectionTextNoImg}>                    
                        <p>
                            <strong>Darktouffe</strong> est le nom de mon entreprise, incarné par ce site. Sur ce site, vous pouvez en apprendre plus sur mes projets en cours et réalisés. Vous pouvez aussi lire des articles à propos de sujets divers et variés, la majorité des sujets tourneront autour de <strong>l’informatique</strong> et la <strong>culture digitale</strong>.
                        </p>
                        <p>
                            Enfin, si vous avez besoin des services de Darktouffe vous pouvez aussi trouver mes coordonnées ici.
                        </p>
                        <p>Bonne visite 😁!</p>
                    </div>
                </section>
                
                <hr />
                
                <section>
                    <span>Projets</span>
                    <div className={`${Styles.SectionContainer} ${Styles.SectionContainerOdd}`}>
                        <div className={Styles.homeSectionText}>
                            <p>
                                Darktouffe c'est d’abord des <strong>projets</strong> qui peuvent être divisés en deux parties:
                            </p>
                            <ul>
                                <li>Des projets dont le but est de <strong>rendre un service</strong> 🚀: Proposer une solution qui permettrait au musée de créer des <strong>expositions digitales</strong>, en apportant une expérience inédite pour le visiteur par exemple.</li>
                                <li>Des projets dont le but est de vous divertir 👾: Il s’agit ici de <strong>jeux vidéo</strong>, qui ne vous laisseront pas de marbre. Ils sont en effet réalisés avec passion et conviction, et n’essaient jamais de répondre uniquement à une tendance mais seulement à enthousiasmer.</li>
                            </ul>
                            <p>
                                A travers ses projets, Darktouffe cherche d’abord à <strong>répondre à un besoin</strong> puis à trouver la <strong>solution technologique</strong>. Aucune limite n’est fixée, et l’innovation est privilégiée, Darktouffe se veut être un <strong>laboratoire</strong> <strong>d’</strong><strong>innovation technologique</strong> ⚗️.
                            </p>
                        </div>
                        <img src={homeItImage} alt="Illustration IT"/>
                    </div>
                </section>
                
                <hr />
                
                <section>
                    <span>Blog</span>
                    <div className={`${Styles.SectionContainer} ${Styles.SectionContainerEven}`}>
                        <img src={homeBlogImage} alt="Illustration Blog"/>
                        <div className={Styles.homeSectionText}>
                            <p>
                                Les nouvelles technologies et tout ce que ça apporte ne laissent personne indifférent, elles créent cependant des zones d’ombre dans la compréhension de celles-ci par chacun d’entre nous. Pour tenter d’éclaircir votre vision, vous trouverez ici plusieurs types d’articles:
                            </p>
                            <ul>
                                <li>Des <strong>articles explicatifs</strong> 🔎, qui vous permettent de comprendre tout ou partie de la technologie abordée.</li>
                                <li>Des <strong>tutoriels</strong> 🛠️, qui vous aideront peut-être à réaliser tout ou partie de votre projet.</li>
                                <li>Des articles centrés sur la <strong>culture</strong> <strong>informatique</strong> et <strong>digitale</strong> 📚, car l’informatique ne se résume pas uniquement à des lignes de codes!</li>
                                <li>Et bien d’autres, le but sera de vous enrichir culturellement sur les sujets liés à l’informatique.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                
                <hr />
                
                <section>
                    <span>Les valeurs défendues</span>
                    <div className={`${Styles.SectionContainer} ${Styles.SectionContainerOdd}`}>
                        <div className={Styles.homeSectionText}>
                            <p>
                                Darktouffe défend toutes les <strong>valeurs sociales</strong> qui permettent l'avancée de la <strong>communauté humaine</strong> que nous sommes 🌍:
                            </p>
                            <ul>
                                <li>L’<strong>égalité des chances</strong> 🤝: Tout être humain devrait pouvoir avoir accès à l’<strong>éducation</strong>, la <strong>culture</strong> et même le <strong>divertissement</strong> de la même manière que n’importe quelle autre personne.</li>
                                <li>L’<strong>innovation</strong> <strong>scientifique</strong> et <strong>technologique</strong> 🔬: Les sciences et les technologies ont grandement améliorés les conditions de vie, cependant, il reste encore à faire. Ces innovations doivent aussi pouvoir profiter à tous.</li>
                                <li>La <strong>lutte</strong> contre toute forme de <strong>discrimination</strong> 🌈: Que ce soit le <strong>féminisme</strong>, la <strong>lutte contre le racisme</strong> et la <strong>xénophobie</strong> ou la <strong>lutte contre l’homophobie</strong> et la <strong>transphobie</strong>, ce site se veut aussi engagé et traitera tout au long de son existence des sujets liés à ces luttes.</li>
                                <li>La promotion de l’<strong>écologie</strong> 🌱: Nous n’avons qu’une planète, il faut la préserver le plus possible. Le <strong>changement climatique</strong> s’accentue de jour en jour et les conséquences sont parfois désastreuses, il faut agir!</li>
                            </ul>
                        </div>
                        <img src={homeCausesImage} alt="Illustration Causes"/>
                    </div>
                </section>
                
                <hr />
                
                <section>
                    <span>Contact</span>
                    <div className={Styles.homeSectionTextNoImg}>   
                        <p>
                            Si vous voulez me <strong>contacter</strong>, une page dédiée y recense tous les <strong>réseaux sociaux</strong> et <strong>sites web</strong> sur lesquels je suis inscrit. Vous pouvez accéder à cette page <Link to="/contact">ici</Link>.
                        </p>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Home;