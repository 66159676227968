import { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const themes = {
    original: {
      "--bg-1": "rgb(46,49,146)",
      "--bg-2": "linear-gradient(70deg, rgba(46,49,146,1) 0%, rgba(0,198,255,1) 100%)",
      "--bg-menu": "#0097e6",
      "--bg-menu-option-hover": "#32abeb",
      "--bg-menu-option-active": "#0069a1",
      "--bg-menu-option-selected": "#32abeb",
      "--user-color": "azure",
      "--menu-position": "initial",
      "--menu-border-botton": "unset",
      "--body-margin-top": "0px",
      "--text-color": "azure",
      "--post-text-color": "#ccead0",
      "--body-menu-display": "block",
      "--home-pro-display": "none",
      "--about-cards-color": "rgba(100, 171, 235, 0.5)",
      "--about-relevant-color": "#d6eefb",
      "--about-box-shadow": "rgba(9, 30, 66, 0.75) 0px 4px 8px -2px, rgba(9, 30, 66, 0.28) 0px 0px 0px 1px",
    },
    original_dark: {
      "--bg-1": "#191919",
      "--bg-2": "#191919",
      "--bg-menu": "#0097e6",
      "--bg-menu-option-hover": "#32abeb",
      "--bg-menu-option-active": "#0069a1",
      "--bg-menu-option-selected": "#32abeb",
      "--user-color": "azure",
      "--menu-position": "initial",
      "--menu-border-botton": "unset",
      "--body-margin-top": "0px",
      "--text-color": "azure",
      "--post-text-color": "#ccead0",
      "--body-menu-display": "block",
      "--home-pro-display": "none",
      "--about-cards-color": "rgba(100, 171, 235, 0.5)",
      "--about-relevant-color": "#d6eefb",
      "--about-box-shadow": "rgba(9, 30, 66, 0.75) 0px 4px 8px -2px, rgba(9, 30, 66, 0.28) 0px 0px 0px 1px",
    },
    professional: {
      "--bg-1": "#e3f2fd",
      "--bg-2": "#e3f2fd",
      "--bg-menu": "azure",
      "--bg-menu-option-hover": "#f7fbff",
      "--bg-menu-option-active": "azure",
      "--bg-menu-option-selected": "azure",
      "--user-color": "#e5d0ba",
      "--menu-position": "fixed",
      "--menu-border-botton": "solid #e5d0ba",
      "--body-margin-top": "60px",
      "--text-color": "#0d47a1",
      "--post-text-color": "#191919",
      "--body-menu-display": "none",
      "--home-pro-display": "block",
      "--home-body-color": "#191919",
      "--hr-color": "#e5d0ba",
      "--section-container-background-color": "#dfe9f3",
      "--section-container-box-shadow" : "rgba(164, 60, 180, 0.2) 0px 7px 29px 0px",
      "--about-cards-color": "rgba(223, 233, 243, 0.5)",
      "--about-relevant-color": "#57d68d",
      "--about-box-shadow": "rgba(164, 60, 180, 0.2) 0px 7px 29px 0px",
    },
    professional_dark: {
      "--bg-1": "#121212",
      "--bg-2": "#1e1e1e",
      "--bg-menu": "#181818",
      "--bg-menu-option-hover": "#252525",
      "--bg-menu-option-active": "#303030",
      "--bg-menu-option-selected": "#3a3a3a",
      "--user-color": "#c9a47a",
      "--menu-position": "fixed",
      "--menu-border-botton": "solid #c9a47a",
      "--body-margin-top": "60px",
      "--text-color": "#e0e0e0", 
      "--post-text-color": "#f5f5f5",
      "--body-menu-display": "none",
      "--home-pro-display": "block",
      "--home-body-color": "#f5f5f5",
      "--hr-color": "#c9a47a",
      "--section-container-background-color": "#222831",
      "--section-container-box-shadow" : "rgba(200, 200, 200, 0.2) 0px 7px 29px 0px",
      "--about-cards-color": "rgba(34, 40, 49, 0.8)",
      "--about-relevant-color": "#57d68d",
      "--about-box-shadow": "rgba(200, 200, 200, 0.2) 0px 7px 29px 0px",
    },
  };

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "professional");

  useEffect(() => {
    const selectedTheme = themes[theme];

    for (let key in selectedTheme) {
      document.documentElement.style.setProperty(key, selectedTheme[key]);
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};