import React from 'react';
import { Link } from "react-router-dom";
import Styles from "../css_modules/Footer.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faC } from '@fortawesome/free-solid-svg-icons';

function Footer() {

    return (
        <footer>
            <div className={Styles.footerContainer}>
                <div class={Styles.footerLogo}>
                    <h2>Darktouffe</h2>
                </div>
                <div class={Styles.footerLinks}>
                    <Link to="/">Accueil</Link>
                    <Link to="/about">À propos</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div class={Styles.footerSocials}>
                    <Link to="https://www.linkedin.com/company/darktouffe"><FontAwesomeIcon icon={faLinkedin} /></Link>
                    <Link to="https://www.instagram.com/darktouffe_company/"><FontAwesomeIcon icon={faInstagram}/></Link>
                    <Link to="https://www.facebook.com/DarkTouffe"><FontAwesomeIcon icon={faFacebook}/></Link>
                    <Link to="https://comeup.com/fr/@darktouffe-co"><FontAwesomeIcon icon={faC} /></Link>
                </div>
                <p class={Styles.footerText}>© 2025 Darktouffe. Tous droits réservés.</p>
            </div>
        </footer>
    )
}

export default Footer;